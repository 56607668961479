import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import { useMediaQuery } from "@material-ui/core"
import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner as StaticBanner } from "../../components/Banners/Kiberponedelnik"

import { Banner as RoundedDynamicBanner } from "../../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { kiberponedelnik } from "../../components/PartnersNew/helpers"
import { NewFooter } from "../../components/NewFooter"
import { TwoChoice } from "../../components/twoChoice"
import { MoreBenefitsRedisign } from "../../components/MorBenefitsRedisgn"
import { Questions } from "../../components/Questions"
import { QUESTIONS_FOR_HALVA_MEDIA } from "../../components/Questions/helpers"

import { getPageData } from "../../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../../helpers/getDynamicBannerUrl"

import { PageData } from "../../interfaces/pageProps"

const bannerDescription = `
– совершайте покупки в интернет-магазинах партнеров Халвы 
<br/>
– участвуйте в розыгрыше призов
`

const subTitlePartners = (
  <>Совершайте покупки в любых интернет-магазинах партнёров и получайте до 36 месяцев рассрочки</>
)

const firstBlockChoice = (
  <>
    Есть Халва – активируйте акцию,{" "}
    <span>совершайте покупки и&nbsp;получайте до&nbsp;36&nbsp;месяцев рассрочки</span>
  </>
)

const secondBlockChoice = (
  <>
    Нет Халвы – оформляйте карту,{" "}
    <span>активируйте акцию и&nbsp;участвуйте в&nbsp;розыгрыше призов</span>
  </>
)

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()
  const mobile = useMediaQuery("(max-width: 599px)")

  const bannerTitle = `До 36 ${
    mobile ? "мес." : "месяцев"
  } рассрочки и&nbsp;розыгрыш&nbsp;100 000 ₽`

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout
      noHeader
      noFooter
      noIndex={noIndex}
      seoData={{
        title:
          'Акция "Киберфортуна" с картой Халва – До 36 месяцев рассрочки и розыгрыш 100 00 рублей',
        description:
          "Совершайте покупки в интернет-магазинах партнеров Халвы и участвуйте в розыгрыше призов. Возможность выиграть до 36 месяцев рассрочки и 100 00 рублей!",
      }}
    >
      <NewHeader
        showHint
        countDownTime={countDownTime}
        hint="Крутите Колесо Фортуны с&nbsp;20.01 по&nbsp;27.01 и получайте увеличенную рассрочку до&nbsp;36&nbsp;месяцев."
        hintTitle={
          <>
            Участвуй в<br /> розыгрыше
          </>
        }
      />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <StaticBanner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="1"
        />
      )}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="3"
      />
      <HowInstallmentWorks
        headTitle="Как принять участие в&nbsp;розыгрыше?"
        variant="kiberponedelnik"
        orderNum="3"
      />
      <FormPKW
        progressBar
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        isGreenApprove
      />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        additionalPartners={kiberponedelnik}
        orderNum="6"
      />
      <TwoChoice
        variant="kiberponedelnik"
        orderNum="7"
        textBlockTitle={firstBlockChoice}
        secondTextBlockTitle={secondBlockChoice}
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="8"
        hasSubtitle
        bottomBlock={false}
      />
      <Questions questionList={QUESTIONS_FOR_HALVA_MEDIA} orderNum="9" />
      <NewFooter ligal={ligal} orderNum="10" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/kiberponedelnik/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
